const glossaryData = [
    { id: 1, term: 'coverage', definition: 'The degree to which specified coverage items are exercised by a test suite, expressed as a percentage.   || Synonyms: "test coverage"' },
    { id: 2, term: 'debugging', definition: 'The process of finding, analyzing and removing the causes of failures in a component or system.' },
    { id: 3, term: 'defect', definition: 'An imperfection or deficiency in a work product where it does not meet its requirements or specifications or impairs its intended use. || See also "error", "failure"' },
    { id: 4, term: 'error', definition: 'A human action that produces an incorrect result. || Synonyms: "mistake" || See also "defect"' },
    { id: 5, term: 'failure', definition: 'An event in which a component or system does not meet its requirements within specified limits. || See also "defect"' },
    { id: 6, term: 'quality', definition: 'The degree to which a work product satisfies stated and implied requirements.' },
    { id: 7, term: 'quality assurance', definition: 'Activities focused on providing confidence that quality requirements will be fulfilled. || Abbreviation - QA || See also "quality management"' },
    { id: 8, term: 'root cause', definition: 'A source of a defect such that if it is removed, the occurrence of the defect type is decreased or removed.' },
    { id: 9, term: 'test analysis', definition: 'The activity that identifies test conditions by analyzing the test basis.' },
    { id: 10, term: 'test basis', definition: 'The body of knowledge used as the basis for test analysis and design.' },
    { id: 11, term: 'test case', definition: 'A set of preconditions, inputs, actions (where applicable), expected results and postconditions, developed based on test conditions. || See also "test step"' },
    { id: 12, term: 'test completion', definition: 'The activity that makes testware available for later use, leaves test environments in a satisfactory condition and communicates the results of testing to relevant stakeholders.' },
    { id: 13, term: 'test condition', definition: 'A testable aspect of a component or system identified as a basis for testing. || Synonyms: "test situation", "test requirement"' },
    { id: 14, term: 'test control', definition: 'The activity that develops and applies corrective actions to get a test project on track when it deviates from what was planned. || See also "test management"' },
    { id: 15, term: 'test data', definition: 'Data needed for test execution. || Synonyms: "test dataset"' },
    { id: 16, term: 'test design', definition: 'The activity that derives and specifies test cases from test conditions.' },
    { id: 17, term: 'test execution', definition: 'The activity that runs a test on a component or system producing actual results.' },
    { id: 18, term: 'test implementation', definition: 'The activity that prepares the testware needed for test execution based on test analysis and design.' },
    { id: 19, term: 'test monitoring', definition: 'The activity that checks the status of testing activities, identifies any variances from planned or expected, and reports status to stakeholders. || See also "test management"' },
    { id: 20, term: 'test object', definition: 'The work product to be tested. || See also "test item"' },
    { id: 21, term: 'test objective', definition: 'The purpose for testing. || Synonyms: "test goal"' },
    { id: 22, term: 'test planning', definition: 'The activity of establishing or updating a test plan.' },
    { id: 23, term: 'test procedure', definition: 'A sequence of test cases in execution order, and any associated actions that may be required to set up the initial preconditions and any wrap up activities post execution.' },
    { id: 24, term: 'test result', definition: 'The consequence/outcome of the execution of a test.  || Synonyms: "outcome", "test outcome", "result"' },
    { id: 25, term: 'testing', definition: 'The process within the software development lifecycle that evaluates the quality of a component or system and related work products. || See also "quality control"' },
    { id: 26, term: 'testware', definition: 'Work products produced during the test process for use in planning, designing, executing, evaluating and reporting on testing.' },
    { id: 27, term: 'validation', definition: 'Confirmation by examination that a work product matches a stakeholder\'s needs.' },
    { id: 28, term: 'verification', definition: 'The process of confirming that a work product fulfills its specification.' },
    { id: 29, term: 'acceptance testing', definition: 'A test level that focuses on determining whether to accept the system. || See also "user acceptance testing"' },
    { id: 30, term: 'black-box testing', definition: 'Testing based on an analysis of the specification of the component or system. || Synonyms: "specification-based testing"' },
    { id: 31, term: 'component integration testing', definition: 'The integration testing of components. || Synonyms: "module integration testing", "unit integration testing"' },
    { id: 32, term: 'component testing', definition: 'A test level that focuses on individual hardware or software components. || Synonyms: "module testing", "unit testing"' },
    { id: 33, term: 'confirmation testing', definition: 'A type of change-related testing performed after fixing a defect to confirm that a failure caused by that defect does not reoccur. || Synonyms: "re-testing"' },
    { id: 34, term: 'functional testing', definition: 'Testing performed to evaluate if a component or system satisfies functional requirements.' },
    { id: 35, term: 'integration testing', definition: 'A test level that focuses on interactions between components or systems.' },
    { id: 36, term: 'maintenance testing', definition: 'Testing the changes to an operational system or the impact of a changed environment to an operational system.' },
    { id: 37, term: 'non-functional testing', definition: 'Testing performed to evaluate that a component or system complies with non-functional requirements.' },
    { id: 38, term: 'regression testing', definition: 'A type of change-related testing to detect whether defects have been introduced or uncovered in unchanged areas of the software.' },
    { id: 39, term: 'shift-left', definition: 'A test approach to perform testing and quality assurance activities as early as possible in the software development lifecycle. || See also "shift-right"' },
    { id: 40, term: 'system integration testing', definition: 'The integration testing of systems.' },
    { id: 41, term: 'system testing', definition: 'A test level that focuses on verifying that a system as a whole meets specified requirements.' },
    { id: 42, term: 'test level', definition: 'A specific instantiation of a test process. || Synonyms: "test stage"' },
    { id: 43, term: 'test step', definition: 'A single interaction between an actor and a test object consisting of an input, an action, and an expected result. || See also "test case"' },
    { id: 44, term: 'test type', definition: 'A group of test activities based on specific test objectives aimed at specific characteristics of a component or system.' },
    { id: 45, term: 'white-box testing', definition: 'Testing based on an analysis of the internal structure of the component or system. || Synonyms: "clear-box testing", "code-based testing", "glass-box testing", "logic-coverage testing", "logic-driven testing", "structural testing", "structure-based testing"' },
    { id: 46, term: 'anomaly', definition: 'A condition that deviates from expectation.' },
    { id: 47, term: 'dynamic testing', definition: 'Testing that involves the execution of the test item. || See also "static testing"' },
    { id: 48, term: 'formal review', definition: 'A review that follows a defined process with a formally documented output.' },
    { id: 49, term: 'informal review', definition: 'A type of review that does not follow a defined process and has no formally documented output.' },
    { id: 50, term: 'inspection', definition: 'A type of formal review that uses defined team roles and measurement to identify defects in a work product, and improve the review process and the software development process. || See also "peer review"' },
    { id: 51, term: 'review', definition: 'A type of static testing in which the quality of a work product or process is evaluated by individuals. || See also "static testing"' },
    { id: 52, term: 'static analysis', definition: 'The process of evaluating a component or system without executing it, based on its form, structure, content, or documentation. || See also "static testing"' },
    { id: 53, term: 'static testing', definition: 'Testing that does not involve the execution of a test item. || See also "dynamic testing", "static analysis", "review"' },
    { id: 54, term: 'technical review', definition: 'A formal review by technical experts that examine the quality of a work product and identify discrepancies from specifications and standards. || See also "peer review"' },
    { id: 55, term: 'walkthrough', definition: 'A type of review in which an author leads members of the review through a work product and the members ask questions and make comments about possible issues. || Synonyms: "structured walkthrough" || See also "peer review"' },
    { id: 56, term: 'acceptance criteria', definition: 'The criteria that a work product must satisfy to be accepted by the stakeholders.' },
    { id: 57, term: 'acceptance test-driven development', definition: 'A collaboration-based test-first approach that defines acceptance tests in the stakeholders\' domain language. || Abbreviation - ATDD || See also "specification by example"' },
    { id: 58, term: 'black-box test technique', definition: 'A test technique based on an analysis of the specification of a component or system. || Synonyms: "black-box test design technique", "specification-based test technique"' },
    { id: 59, term: 'boundary value analysis', definition: 'A black-box test technique in which test cases are designed based on boundary values. || See also "boundary value"' },
    { id: 60, term: 'branch coverage', definition: 'The coverage of branches in a control flow graph.' },
    { id: 61, term: 'checklist-based testing', definition: 'An experience-based test technique in which test cases are designed to exercise the items of a checklist.' },
    { id: 62, term: 'collaboration-based test approach', definition: 'An approach to testing that focuses on defect avoidance by collaborating among stakeholders.' },
    { id: 63, term: 'test item', definition: 'A part of a test object used in the test process. || See also "test object"' },
    { id: 64, term: 'coverage item', definition: 'An attribute or combination of attributes derived from one or more test conditions by using a test technique.' },
    { id: 65, term: 'decision table testing', definition: 'A black-box test technique in which test cases are designed to exercise the combinations of conditions and the resulting actions shown in a decision table.' },
    { id: 66, term: 'equivalence partitioning', definition: 'A black-box test technique in which test conditions are equivalence partitions exercised by one representative member of each partition. || Synonyms: "partition testing"' },
    { id: 67, term: 'error guessing', definition: 'A test technique in which tests are derived on the basis of the tester\'s knowledge of past failures, or general knowledge of failure modes.' },
    { id: 68, term: 'experience-based test technique', definition: 'A test technique based on the tester\'s experience, knowledge and intuition. || Synonyms: "experience-based test design technique", "experience-based technique"' },
    { id: 69, term: 'exploratory testing', definition: 'An approach to testing in which the testers dynamically design and execute tests based on their knowledge, exploration of the test item and the results of previous tests. || See also "test charter"' },
    { id: 70, term: 'state transition testing', definition: 'A black-box test technique in which test cases are designed to exercise elements of a state transition model. || Synonyms: "finite state testing"' },
    { id: 71, term: 'statement coverage', definition: 'The coverage of executable statements.' },
    { id: 72, term: 'test technique', definition: 'A procedure used to define test conditions, design test cases, and specify test data. || Synonyms: "test design technique"' },
    { id: 73, term: 'white-box test technique', definition: 'A test technique only based on the internal structure of a component or system. || Synonyms: "white-box test design technique", "structure-based test technique"' },
    { id: 74, term: 'defect management', definition: 'The process of recognizing, recording, classifying, investigating, fixing and disposing of defects.' },
    { id: 75, term: 'defect report', definition: 'Documentation of the occurrence, nature, and status of a defect. || Synonyms: "bug report"' },
    { id: 76, term: 'entry criteria', definition: 'The set of conditions for officially starting a defined task. || See also "exit criteria"' },
    { id: 77, term: 'exit criteria', definition: 'The set of conditions for officially completing a defined task. || Synonyms: "test completion criteria", "completion criteria" || See also "entry criteria"' },
    { id: 78, term: 'product risk', definition: 'A risk that impacts the quality of a product. || See also "risk"' },
    { id: 79, term: 'project risk', definition: 'A risk that impacts project success. || See also "risk"' },
    { id: 80, term: 'risk', definition: 'A factor that could result in future negative consequences. || See also "product risk", "project risk"' },
    { id: 81, term: 'risk analysis', definition: 'The overall process of risk identification and risk assessment.' },
    { id: 82, term: 'risk assessment', definition: 'The process to examine identified risks and determine the risk level.' },
    { id: 83, term: 'risk control', definition: 'The overall process of risk mitigation and risk monitoring.' },
    { id: 84, term: 'risk identification', definition: 'The process of finding, recognizing and describing risks.' },
    { id: 85, term: 'risk level', definition: 'The measure of a risk defined by risk impact and risk likelihood. || Synonyms: "risk exposure"' },
    { id: 86, term: 'risk management', definition: 'The process for handling risks.' },
    { id: 87, term: 'risk mitigation', definition: 'The process through which decisions are reached and protective measures are implemented for reducing or maintaining risks to specified levels.' },
    { id: 88, term: 'risk monitoring', definition: 'The activity that checks and reports the status of known risks to stakeholders.' },
    { id: 89, term: 'risk-based testing', definition: 'A test approach in which the management, selection, prioritization, and use of test activities and resources are based on corresponding risk types and risk levels.' },
    { id: 90, term: 'test approach', definition: 'The manner of implementing testing tasks.' },
    { id: 91, term: 'test completion report', definition: 'A type of test report produced at completion milestones that provides an evaluation of the corresponding test items against exit criteria. || Synonyms: "test summary report"' },
    { id: 92, term: 'quality control', definition: 'Activities designed to evaluate the quality of a component or system. || Abbreviation - QC || See also "testing"' },
    { id: 93, term: 'user acceptance testing', definition: 'A type of acceptance testing performed to determine if intended users accept the system. Abbreviation - UAT || See also "acceptance testing"' },
    { id: 94, term: 'test plan', definition: 'Documentation describing the test objectives to be achieved and the means and the schedule for achieving them, organized to coordinate testing activities. || See also "master test plan", "level test plan", "test scope"' },
    { id: 95, term: 'test management', definition: 'The process of planning, scheduling, estimating, monitoring, reporting, controlling, and completing test activities. || See also "test control", "test monitoring"' },
    { id: 96, term: 'test progress report', definition: 'A type of periodic test report that includes the progress of test activities against a baseline, risks, and alternatives requiring a decision. || Synonyms: "test status report"' },
    { id: 97, term: 'test pyramid', definition: 'A graphical model representing the relationship of the amount of testing per level, with more at the bottom than at the top.' },
    { id: 98, term: 'testing quadrants', definition: 'A classification model of test types/test levels in four quadrants, relating them to two dimensions of test objectives: supporting the product team versus critiquing the product, and technology-facing versus business-facing.' },
    { id: 99, term: 'quality management', definition: 'The process of establishing and directing a quality policy, quality objectives, quality planning, quality control, quality assurance, and quality improvement for an organization. || See also "quality assurance"' },
    { id: 100, term: 'test automation', definition: 'The conversion of test activities to automatic operation.' },
    { id: 101, term: 'shift-right', definition: 'A test approach to test a system continuously in production. || See also "shift-left", "testing in production"' },
    { id: 102, term: 'peer review', definition: 'A type of review performed by others with the same abilities to create the work product. || See also "technical review", "inspection", "walkthrough"'},
    { id: 103, term: 'specification by example', definition: 'A development technique in which the specification is defined by examples. || Abbreviation - SBE || See also "acceptance test-driven development"'},
    { id: 104, term: 'boundary value', definition: 'A minimum or maximum value of an ordered equivalence partition. || See also "boundary value analysis"'},
    { id: 105, term: 'test charter', definition: 'Documentation of the goal or objective for a test session. || Synonyms "charter" || See also "exploratory testing"'},
    { id: 106, term: 'master test plan', definition: 'A test plan that is used to coordinate multiple test levels or test types. || See also "test plan"'},
    { id: 107, term: 'level test plan', definition: 'A test plan that typically addresses one test level. || See also "test plan"'},
    { id: 108, term: 'test scope', definition: 'A description of the test object and its features to be tested. || See also "test plan"'},
    { id: 109, term: 'testing in production', definition: 'A test type performed in the production environment with live data. || See also "post-release testing", "shift-right"' },
    { id: 110, term: 'post-release testing', definition: 'A test level performed after the release of a system to validate that it can be deployed in operational environments. || See also "testing in production"'}
  ];
  
  export default glossaryData;